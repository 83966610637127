/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

import axiosLibrary from 'axios';

const token = document.head.querySelector('meta[name="csrf-token"]');

const timeout = RMP_ENVIRONMENT === 'production' ? 6000 : 60000;

const axiosConfig = {
  headers: {
    common: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  },
  timeout,
};

// console.log(timeout);

if (token) {
  axiosConfig.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
  // console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

const axios = axiosLibrary.create(axiosConfig);

export { axios }; //eslint-disable-line
