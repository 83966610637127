// Setup vue error logging with BugSnag
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

// For local dev just log error, in prod we notify bugsnag.
let bugsnagClient = { // eslint-disable-line
  notify(error, options = null) {
    console.error(error, options);
  },
};

if (process.env.MIX_APP_ENV === 'production') {
  console.log('BugSnag - production');
  const apiKey = window.location.href.includes('/control/vue') ? process.env.MIX_BUGSNAG_JS_API_KEY_ADMIN : process.env.MIX_BUGSNAG_JS_API_KEY_FRONT;
  Bugsnag.start({
    apiKey,
    appVersion: process.env.MIX_BUGSNAG_APP_VERSION,
    plugins: [new BugsnagPluginVue()],
  });

  bugsnagClient = Bugsnag;
}

export { bugsnagClient }; //eslint-disable-line
