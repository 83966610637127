import { axios } from '@/axios';

export default function ({ inputSelector, $loadingContainer, customParams }) {
  return {
    selector: inputSelector,
    debounce: 300,
    threshold: 2,
    placeHolder: 'Start searching...',
    resultsList: {
      maxResults: 10,
      noResults: true,
      tabSelect: true,
    },
    resultItem: {
      highlight: true,
    },
    data: {
      keys: ['name'],
      cache: false,
      src: async (query) => {
        try {
          console.log('global-autocomplete:load-results');

          // Loading placeholder text
          $loadingContainer.classList.add('is-loading');

          let params = {
            params: { s: query, target: 'jobs' },
          };

          if (customParams) {
            params = { params: { ...params.params, ...customParams.params } };
          }

          const response = await axios.get('/suggestions.php', params);

          // loading state OFF
          $loadingContainer.classList.remove('is-loading');

          // Returns Fetched data
          return [...response.data.data.companies];
        } catch (error) {
          return error;
        }
      },
    },
  };
}
