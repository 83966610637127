export default function () {
  return {
    ui: {
      loading: false,
      viewLinks: false,
      showWidget: true,
    },
    remote: {
      results: [],
      count: null,
      categories: null,
    },
    config: {
      base: RMP_SITE_URL,
    },
  };
}
