const ga = {
  send({ category = 'shortlisting', label, value }) {
    console.log({ category, label, value });

    try {
      window.gtag('event', label, {
        event_category: category,
        event_label: value,
      });
    } catch (e) {
      console.log(e);
    }
    // The params are actually named incorrectly with the old version
    // window.ga(
    //   'send',
    //   'event',
    //   category, // category
    //   label, // action
    //   value, // label
    // );
  },
};

export { ga };

export default {
  install(Vue) {
    Vue.prototype.$ga = ga;
  },
};
