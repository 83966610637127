<template>
  <a class="HotJob-job RMP-JobsWidget--Job p-md">
    <div
      data-v-7df7741e=""
      class="HotJob-link"
    >
      <figure class="Logo">
        <img
          :src="defaultLogo"
          width="60"
          height="60"
        >
      </figure>
      <div class="HotJob-specs">
        <h3
          class="HotJob-title"
          style="margin: 0"
        >
          <span class="placeholder" />
        </h3>
        <span class="RMP-JobsWidget--JobCompanyName companyName">
          <span class="placeholder" />
        </span>
        <span class="Tag Tag--blue HotJob-JobTag"><span class="placeholder" /></span>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  data() {
    return {
      defaultLogo: 'https://dbx9jsyriv02l.cloudfront.net/assets/placeholder.png',
    };
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.placeholder {
  display: block;
  background: #EEE;
  width: 180px;
  max-width: 100%;
  height: 20px;
  margin-bottom: 4px;
}
.RMP-JobsWidget--JobTag {
  .placeholder {
    width: 50px;
    display: block;
    height: 20px;
  }
}
</style>
