import state from './jobs-widget-state';
import mutations from './jobs-widget-mutations';
import getters from './jobs-widget-getters';
import actions from './jobs-widget-actions';

export default {
  namespaced: true,
  state: state(),
  mutations,
  getters,
  actions,
};
