import mitt from 'mitt';

const emitter = mitt();

const eventBus = {
  ...emitter,
  $on: (event, data) => emitter.on(event, data),
  $emit: (event, data) => emitter.emit(event, data),
  $off: (event, data) => emitter.off(event, data),
};

export default eventBus;
