import { createStore } from 'vuex';

import jobs from './jobs-widget';
import user from './user-widget';

const store = createStore({
  strict: process.env.NODE_ENV !== 'production',

  modules: {
    jobs,
    user,
  },

  state: {},

  mutations: {},

  getters: {},
});

export default store;
