/* eslint arrow-body-style: "off" */

/*
 * Filters
 */
const filters = {
  round: (value, precision = 0) => {
    if (!value) {
      return 0;
    }
    /* eslint-disable */
    const multiplier = Math.pow(10, precision);
    /* eslint-enable */
    return Math.round(value * multiplier) / multiplier;
  },

  // http://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
  commas: (value) => {
    if (!value) {
      return 0;
    }
    const parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  },

  trim: (value, limit = 10) => {
    if (!value) { return ''; }
    return value.substring(0, limit);
  },

  roundUp: (value) => {
    return Math.ceil(value);
  },

  limitBy: (array, pageSize, pageOffset) => {
    return array.slice(pageOffset, pageOffset + pageSize);
  },

  dateFormat: (date) => {
    if (!date) {
      return '';
    }
    date = date.substring(0, 16);
    return date.split('T').join(' ');
  },

  percentage: (value) => {
    if (!value) {
      return '0 %';
    }
    return `${value}%`;
  },

  jsonPretty: (obj, spaces = 2) => {
    if (!obj) { return ''; }

    return JSON.stringify(obj, null, spaces);
  },

  initalCap: (string) => {
    if (!string) { return ''; }
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  dynamicFilter: (item, filter, ...params) => {
    if (!filter) { return item; }

    const filterMethod = filters[filter];
    return filterMethod(item, ...params);
  },
};

export default {
  install: (app) => {
    app.config.globalProperties.$filters = filters;
  },
};

export { filters };
