// This will be superseded by resources/assets/vue/blog/blog.js
// once the blog is integrated into rate fully.

import { createApp } from 'vue';
import './polyfills';
import AxiosPlugin from './axios';
import { bugsnagClient } from './bugsnag';
import EventBusPlugin from './event-bus';
import GaPlugin from './google-analytics';
import store from './vuex/index';
import filterPlugin from './filters';

import vueJobWidget from './components/jobs-widget';
import vueUserWidget from './components/user-widget';
import vueUserSidebarWidget from './components/user-widget/sidebar';

import '@/vanilla/flash-messages';
import '@/vanilla/mobile-menu';
import '@/vanilla/search/global-autocomplete';

function bindEnvValues(appInstance) {
  appInstance.config.globalProperties.RMP_CDN_URL = process.env.MIX_CDN_URL;
  appInstance.config.globalProperties.RMP_ENVIRONMENT = process.env.MIX_APP_ENV;
  appInstance.config.globalProperties.RMP_SITE_URL = process.env.APP_URL;
}

function bindGlobalVueProps(appInstance) {
  appInstance.config.globalProperties.$bus = EventBusPlugin;
  appInstance.config.globalProperties.$ga = GaPlugin;
  appInstance.config.globalProperties.$bugsnag = bugsnagClient;
  appInstance.config.globalProperties.$axios = AxiosPlugin;
  appInstance.use(store);
  appInstance.use(filterPlugin);
  bindEnvValues(appInstance);

  if (appInstance.config.globalProperties.RMP_ENVIRONMENT === 'production') {
    appInstance.use(bugsnagClient.getPlugin('vue'));
  }
}

/* eslint-disable no-new */
const jobWidgets = document.querySelectorAll('.vue-jobs-widget');

Array.from(jobWidgets).forEach((jobWidget) => {
  const appInstance = createApp({
    components: {
      'vue-jobs-widget': vueJobWidget,
    },
  });

  bindGlobalVueProps(appInstance);
  appInstance.mount(jobWidget);
});

const userWidgets = document.querySelectorAll('.vue-user-widget');

Array.from(userWidgets).forEach((userWidget) => {
  const appInstance = createApp({
    components: {
      'vue-user-widget': vueUserWidget,
    },
  });

  bindGlobalVueProps(appInstance);
  appInstance.mount(userWidget);
});

const userSidebarWidgets = document.querySelectorAll('.vue-user-sidebar-widget');

Array.from(userSidebarWidgets).forEach((userWidget) => {
  const appInstance = createApp({
    components: {
      'vue-user-sidebar-widget': vueUserSidebarWidget,
    },
  });

  bindGlobalVueProps(appInstance);
  appInstance.mount(userWidget);
});
