import state from './user-widget-state';
import mutations from './user-widget-mutations';
import getters from './user-widget-getters';
import actions from './user-widget-actions';

export default {
  namespaced: true,
  state: state(),
  mutations,
  getters,
  actions,
};
