(() => {
  const $flashMessages = document.querySelectorAll('.Notice');

  if ($flashMessages === null) {
    return false;
  }

  // Turn off timeouts.
  // setTimeout(() => {
  //   $flashMessages.forEach((flashMessage) => {
  //     flashMessage.remove();
  //   });
  // }, 4000);

  $flashMessages.forEach((flashMessage) => {
    const $closeButton = flashMessage.querySelector('.Notice-closeIcon');

    if ($closeButton === null) {
      return false;
    }

    $closeButton.addEventListener('click', () => {
      flashMessage.remove();
    });

    return true;
  });

  return true;
})();
