import { mapMutations, mapState } from 'vuex';

export default {
  props: {
    displayTitle: {
      type: String,
      default: 'Register for job alerts. It\'s FREE!',
    },
    displayDescription: {
      type: String,
      default: 'We\'ll send personalised internship & placement opportunities straight to your inbox. ',
    },
    displaySuccess: {
      type: String,
      default: 'Welcome! You have successfully signed up to receive job alerts & careers advice.',
    },
    displayDisclaimer: {
      type: String,
      default: 'By signing up you\'re happy to receive job alerts from RateMyPlacement. You can unsubscribe at any time.',
    },
    securityNonce: {
      type: String,
      default: '',
    },
    ajaxUrl: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      ui: {
        loading: false,
      },
      config: {
        url: `${RMP_SITE_URL}/api/1/wp/create`,
      },
      form: {
        email: null,
      },
      validation: {
        email: null,
      },
    };
  },

  computed: {
    ...mapState('user', {
      user: (state) => state.user,
    }),

    postParams() {
      return {
        action: 'rate_register_user',
        nonce: this.securityNonce,
        import_comment: 'Blog',
        email: this.form.email,
      };
    },
  },

  methods: {
    ...mapMutations('user', {
      updateUserSuccess: 'updateUserSuccess',
    }),

    submit() {
      if (this.ui.loading) {
        return false;
      }

      if (this.form.email === null) {
        this.validation.email = 'Please enter your email address';
        return false;
      }

      this.validation.email = null;

      this.ui.loading = true;
      return this.$axios({
        method: 'post',
        url: this.ajaxUrl,
        params: this.postParams,
      })
        .then((response) => {
          if (response.data.success) {
            this.updateUserSuccess({ success: true });
          } else {
            this.validation.email = response.data.data;
          }
          this.ui.loading = false;
        })
        .catch(() => {
          this.ui.loading = false;
          this.validation.email = 'Sorry something went wrong during registration. Please try again.';
        });
    },
  },
};
