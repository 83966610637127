// Lib: https://tarekraafat.github.io/autoComplete.js/
// Ref: https://tarekraafat.github.io/autoComplete.js/#/playground
//
// Notes:
// replaces resources/assets/vue/components/common/global-search-bar.vue
// implementation as its gets flagged by google lighthouse for LCP

import AutoComplete from '@tarekraafat/autocomplete.js';
import generateConfig from './autocomplete-config';

const $container = document.querySelector('#js-global-autocomplete');
const $form = document.querySelector('.SiteSearch-form');

function setupGlobalAutocomplete() {
  const inputSelector = '#js-global-autocomplete-field';

  const $loadingContainer = $container.querySelector('.js-megaSearch-button');

  const config = generateConfig({ inputSelector, $loadingContainer });

  const autoCompleteJS = new AutoComplete(config);

  autoCompleteJS.input.addEventListener('init', (event) => {
    console.log('global-autocomplete:init', event);
  });

  autoCompleteJS.input.addEventListener('selection', (event) => {
    console.log('global-autocomplete:selected', event.detail);

    autoCompleteJS.input.value = event.detail.selection.value.name;

    $form.submit();
  });
}

if ($container !== null) {
  console.log('global-autocomplete:present');
  setupGlobalAutocomplete();
}
