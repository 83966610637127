export default {
  requestUrl: (state) => (searchUrl) => {
    let normalisedSearchUrl = searchUrl;

    if (searchUrl.charAt(0) === '/') {
      normalisedSearchUrl = searchUrl.slice(1);
    }

    return `${state.config.base}${normalisedSearchUrl}`;
  },
};
