export default function () {
  let signedUp = localStorage.getItem('rmp:blog-registration');

  try {
    signedUp = JSON.parse(signedUp);
  } catch (e) {
    signedUp = false;
  }

  if (signedUp !== true) {
    signedUp = false;
  }

  return {
    user: {
      signedUp,
      success: false,
    },
  };
}
