export default {
  updateUser(state, payload) {
    state.user = { ...state.user, ...payload };
  },

  updateUserSuccess(state, { success }) {
    state.user.success = success;

    if (success === true) {
      localStorage.setItem('rmp:blog-registration', JSON.stringify(true));
    }
  },
};
