function setupMobileMenu() {
  // General Overlay
  const $overlay = document.querySelector('.Overlay');

  // Global - Show the mobile menu
  const $mobileMenuTrigger = document.querySelector('.js-mobileMenuTrigger');
  const $bodySelector = document.querySelector('body');
  const $mainMenu = document.querySelector('.Nav');

  function closeMenu(element) {
    $bodySelector.style.height = 'auto';
    $bodySelector.style.overflow = 'auto';

    element.classList.remove('is-opened');
    $overlay.classList.remove('is-opened', 'Menu');
    $bodySelector.classList.remove('is-opened');
  }

  function openMenu(element) {
    const windowHeight = window.innerHeight;

    $bodySelector.style.overflow = 'hidden';
    $bodySelector.style.height = `${windowHeight}px`;

    $bodySelector.classList.add('is-opened');
    $overlay.classList.add('is-opened', 'Menu');
    element.classList.add('is-opened');
  }

  $mobileMenuTrigger.addEventListener('click', (e) => {
    e.preventDefault();
    if ($mainMenu.classList.contains('is-opened')) {
      closeMenu($mainMenu);
    } else {
      openMenu($mainMenu);
    }
  });

  $overlay.addEventListener('click', (e) => {
    e.preventDefault();
    const $openedItems = document.querySelectorAll('.is-opened');
    $openedItems.forEach(($item) => {
      closeMenu($item);
    });
  });
}

if (document.querySelector('.Nav') !== null) {
  setupMobileMenu();
}
